$logo-font: 'Great Vibes', cursive;
$primary-font: 'Playfair Display', serif;
$secondary-font: 'Comfortaa', sans-serif;

$dark-color: #131313;
$light-color: #fff;
$accent-color: #ff9b41;
$transparentBlack: rgba(0, 0, 0, .3);
$dark-grey: #313131;
$middle-grey: #787878;
$light-grey: #919191;

$btn-size: 30px;
$border-radius: 10px;

$border: 1px solid $light-grey;
$box-shadow: 0px 0px 5px 0px rgb(209, 209, 209);

// topbar height
$topbar-desktop-height: 95px;
$topbar-tablet-height: 85px;
$topbar-mobile-height: 75px;

// categories height
$categories-desktop-height: 70px;
$categories-tablet-height: 55px;

// footer
$footer-height: 90px;