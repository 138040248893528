.menu-toggler {
    display: none;
    margin-right: 10px;
    width: 35px;
    height: 30px;
    position: relative;

    @media (max-width: 675px) {
        display: block;
    }

    div {
        display: block;
        height: 4px;
        width: 35px;
        border-radius: 2px;
        background-color: $dark-color;
        position: absolute;
        transition: all .15s ease-in-out;

        &:nth-of-type(1) {
            top: 0;
        }

        &:nth-of-type(2) {
            top: calc(50% - 2px);
        }

        &:nth-of-type(3) {
            bottom: 0;
        }
    }

    &.active {
        z-index: 1000;
        
        div {
            &:nth-of-type(1) {
                transform: rotate(45deg) translateX(6px) translateY(10px);
            }

            &:nth-of-type(2) {
                transform: translateX(-110%);
                opacity: 0;
            }
    
            &:nth-of-type(3) {
                transform: rotate(-45deg) translateX(9px) translateY(-12px);
            }
        }
    }
}