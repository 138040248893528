.save-btn {
    z-index: 10;
    @include icon('../../assets/icons8-favorite-50.png', 35px, 40px, 25px, 25px);
    background-color: $light-color;
    border-radius: 0 0 $border-radius $border-radius;
    border: none;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    transform: translateY(-100%);
    transition: .15s ease-in-out;

    @media (max-width: 768px) {
        transform: translateY(0);
    }

    &:hover {
        background-color: rgba($light-color, .9);
    }

    &.saved {
        transform: translateY(0);
        background-image: url('../../assets/saved.png');
    }
}