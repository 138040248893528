.more-recipes-loader {
    height: 42px;
    padding: 10px 0;
    display: none;

    &.active {
        display: block;
    }

    div {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: .2rem;
        border-radius: 50%;
        transform: scale(0);
        animation: scaling 1.5s ease-in-out infinite;

        $delays: (0: 0s, 1: 0.2s, 2: 0.4s, 3: 0.6s, 4: 0.8s, 5: 1s);
        @include set-childs-animation-delays($delays);
    }
}

@keyframes scaling{
    0%, 100% {
      transform: scale(0.2);
      background-color: rgba($light-color, .5);
    }

    40% {
      transform: scale(1);
      background-color: $light-grey;
    }

    50% {
      transform: scale(1);
      background-color: $accent-color;
    }
}