@import './variables';
@import './mixins';
@import './typography';

body {
    min-width: 300px;

    &, html, * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
}

.topbar {
    @include flexible($justify: space-between, $alignment: center);
    padding: .8rem 1.5rem;
    box-shadow: $box-shadow;
    background-color: $light-color;
    height: $topbar-desktop-height;

    @media (max-width: 768px) {
        height: $topbar-tablet-height;
    }
    
    @media (max-width: 480px) {
        height: $topbar-mobile-height;
    }

    @media (max-width: 410px) {
        padding: .3rem .5rem;
    }

    .left-side {
        @include flexible($alignment: center);
    }

    .saved-link {
        display: block;
        @include icon-btn('../../assets/icons8-favorites-64.png');
        padding-left: 30px;
    }

    .logo {
        text-decoration: none;
        margin: 5px 0 0 20px;
        display: block;

        img {
            height: 60px;
            
            @media (max-width: 768px) {
                height: 50px;
            }

            @media (max-width: 330px) {
                height: 45px;
            }
        }
    }
}

.footer {
    font-family: $secondary-font;
    color: $dark-color;
    @include flexible($direction: column, $alignment: center, $justify: center);
    background-color: $light-color;
    box-shadow: $box-shadow;
    height: $footer-height;

    p:first-of-type {
        margin-bottom: 5px;
    }

    .api-link,
    .dev-link {
        font-size: 1.1rem;
        text-decoration: none;
        color: $dark-color;

        &:hover {
            text-decoration: underline;
        }
    }

    .api-link {
        font-family: "Titillium Web", sans-serif;
    }

    .dev-link {
        padding-right: 20px;
        background-image: url(../../assets/github-logo.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: 15px 15px;
    }
}