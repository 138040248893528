.star-outer {
    position: relative;
    font-size: 1.3rem;
    line-height: 1.3rem;
    display: inline-block;

    &::before {
        content: '\2605 \2605 \2605 \2605 \2605';
        color: #cdcdcd;
    }

    .star-inner {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        &::before {
            content: '\2605 \2605 \2605 \2605 \2605';
            color: gold;
        }
    }
}

.rating, .review-count {
    font-size: 1rem;
}

.rating {
    margin-left: 10px;
}

.review-count {
    margin-left: 3px;
    color: #919191;
}