@import './main-loader';
@import './show-more-loader';
@import './rating';
@import './save-recipe-btn';

.recipes {
    padding: 20px;

    &-container {
        max-width: 1200px;
        margin: 0 auto 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        grid-gap: 30px;
        transition: .2s ease-in;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        
        @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 480px) {
            grid-template-columns: 1fr;
        }

        .recipe-card {
            @include flexible($direction: column, $alignment: center);
            overflow: hidden;
            position: relative;
            text-decoration: none;
            color: $dark-color;
            border-radius: 5px;
            box-shadow: $box-shadow;
            transition: .1s cubic-bezier(0.165, 0.84, 0.44, 1);

            .card-photo-wrap {
                overflow: hidden;
                border-radius: 5px 5px 0 0;

                .card-photo {
                    padding: 1rem;
                    border-radius: 50%;
                    width: 100%;
                    transition: .2s ease-in-out;

                    @media (max-width: 768px) {
                        border-radius: 0;
                        transform: scale(1.3);
                    }
                }
            }

            &:hover {
                box-shadow: 0px 0px 10px 0px rgb(170, 169, 169);

                .save-btn {
                    transform: translateY(0);
                }

                .card-photo {
                    border-radius: 0;
                    transform: scale(1.3);
                }
            }

            .card-description {
                @include flexible($direction: column, $alignment: center, $justify: center);
                padding: 0 10px 10px;
    
                .card-description-name {
                    font-family: $primary-font;
                    font-size: 1.2rem;
                    text-align: center;
                    padding: 10px 0;
                }

                .card-description-rating {
                    font-family: $secondary-font;
                    @include flexible($alignment: center);
                }
            }
        }
    }
}